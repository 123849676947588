// A React component to display the total submissions counter
import React, { useState, useEffect } from "react";
import "../styles/TotalSubmissionsCounter.css";

const TotalSubmissionsCounter = () => {
    const [totalSubmissions, setTotalSubmissions] = useState(0);

    useEffect(() => {
        const fetchTotalSubmissions = async () => {
            try {
                const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:4000";
                const response = await fetch(`${API_BASE_URL}/api/total-submissions`);

                if (response.ok) {
                    const data = await response.json();
                    setTotalSubmissions(data.totalSubmissions);
                } else {
                    console.error("Failed to fetch total submissions.");
                }
            } catch (error) {
                console.error("Error fetching total submissions:", error);
            }
        };

        fetchTotalSubmissions();
    }, []);

    return (
        <div className="total-submissions-counter">
            <p>Total Submissions: <strong>{totalSubmissions}</strong></p>
        </div>
    );
};

export default TotalSubmissionsCounter;
