import React, { useState } from "react";
import { Filter } from "bad-words";
import QuestionSection from "./QuestionSection";
import SuccessModal from "./SuccessModal";
import "../styles/FormPage.css"; // Importing component-specific styles

const FormPage = () => {
    const [formData, setFormData] = useState({
        email: "",
        askedQuestion: {
            question: "",
            industry: "",
            company: "",
            designation: "",
            yearsExperience: "",
            year: "",
        },
        receivedQuestion: {
            question: "",
            industry: "",
            company: "",
            designation: "",
            yearsExperience: "",
            year: "",
        },
        receiveUpdates: true,
    });

    const [captchaToken, setCaptchaToken] = useState(null);
    const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

    // Handle form field changes
    const handleChange = (section, field, value) => {
        setFormData((prev) => ({
            ...prev,
            [section]: {
                ...prev[section],
                [field]: value,
            },
        }));
    };

    // Generate reCAPTCHA token when the checkbox is clicked
    const handleCaptchaVerification = async () => {
        await grecaptcha.ready(async () => {
            const token = await grecaptcha.execute("6Led1ZAqAAAAAPnV_rM07Pg1m_ouqdbPwF0mrCzg", {
                action: "submit_form",
            });
            setCaptchaToken(token);
            setIsCaptchaVerified(true); // Enable the submit button
        });
    };

    // Add this function inside FormPage.jsx
    const fetchUserLocation = async () => {
        try {
            const response = await fetch("https://ipapi.co/json/");
            if (response.ok) {
                const data = await response.json();
                return {
                    city: data.city || "Unknown",
                    region: data.region || "Unknown",
                    country: data.country_name || "Unknown",
                };
            }
        } catch (error) {
            console.error("Error fetching location:", error);
        }
        return { city: "Unknown", region: "Unknown", country: "Unknown" };
    };

    // Form submission handler
    const handleSubmit = async (e) => {
        e.preventDefault();

        const location = await fetchUserLocation();
        const filter = new Filter();

        // Check for profanity
        if (
            filter.isProfane(formData.askedQuestion.question) ||
            filter.isProfane(formData.askedQuestion.industry) ||
            filter.isProfane(formData.askedQuestion.company) ||
            filter.isProfane(formData.askedQuestion.designation) ||
            filter.isProfane(formData.receivedQuestion.question) ||
            filter.isProfane(formData.receivedQuestion.industry) ||
            filter.isProfane(formData.receivedQuestion.company) ||
            filter.isProfane(formData.receivedQuestion.designation)
        ) {
            alert("Your submission contains inappropriate language. Please revise it.");
            return;
        }

        // Validate email address
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
            alert("Please enter a valid email address.");
            return;
        }

        // Ensure CAPTCHA token is available
        if (!captchaToken) {
            alert("Please verify you are not a robot!");
            return;
        }

        const formDataWithLocation = {
            ...formData,
            location, // Attach location to the form data
        };

        const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:4000";

        try {
            const formResponse = await fetch(`${API_BASE_URL}/api/submit`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formDataWithLocation),
            });
            const responseData = await formResponse.clone().json();

            if (formResponse.status === 200) {
                setIsSuccessModalOpen(true);
            } else if (formResponse.status === 409) {
                if (window.confirm("Email already exists. Would you like to add another entry?")) {
                    console.log("setting form data to null");
                    setFormData({
                        email: formData.email, // Retain the email address
                        askedQuestion: { question: "", industry: "", company: "", designation: "", yearsExperience: "", year: "" },
                        receivedQuestion: { question: "", industry: "", company: "", designation: "", yearsExperience: "", year: "" },
                        receiveUpdates: formData.receiveUpdates, // Retain their updates preference
                    });
                }
                else {
                    alert(responseData.message);
                }
            } else {
                alert(`Form submission error: ${formResponse.statusText}`);
            }
        } catch (err) {
            console.log("Returned Err", err);
            alert("An error occurred. Please try again.");
        }
    };

    return (
        <div className="form-page">
            <header className="form-header">
                <h1>Your Stories. Your Wisdom. Your Legacy.</h1>
                <p>Unravel the questions that made you pause, think, and grow. Let’s shape future interviews together!</p>
            </header>

            <main className="form-container">
                <div className="form-card">
                    <form onSubmit={handleSubmit}>
                        {/* Introductory Paragraph */}
                        <div className="form-intro">
                            <p>
                                The world of interviews evolves as fast as technology. From unexpected twists to intellectual challenges, every question leaves a mark.
                                Now’s your chance to share those moments—the good, the tricky, and the downright genius—to help others ace their journey.
                            </p>
                            <p>
                                Let’s redefine what it means to be prepared. Together.
                            </p>
                        </div>
                        <p className="mandatory-text">
                            <em>Fields marked with <span className="mandatory-asterisk">*</span> are mandatory.</em>
                        </p>

                        <div className="form-field-inline">
                            <label htmlFor="email">
                                Email Address <span className="mandatory-asterisk">*</span>
                            </label>
                            <input
                                type="email"
                                id="email"
                                value={formData.email}
                                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                placeholder="Your email, your story—no spam, we promise!"
                                required
                            />
                        </div>

                        <QuestionSection
                            title="As an Interviewer"
                            description="The question that left candidates stunned or smiling—what was it? Spill the beans!"
                            section="askedQuestion"
                            data={formData.askedQuestion}
                            onChange={handleChange}
                            mandatoryFields={['question', 'industry', 'company', 'designation']}
                        />

                        <QuestionSection
                            title="As an Interviewee"
                            description="That one question that had you second-guessing life itself? Share the moment!"
                            section="receivedQuestion"
                            data={formData.receivedQuestion}
                            onChange={handleChange}
                            mandatoryFields={['question', 'industry', 'company', 'designation']}
                        />

                        <div className="form-section form-updates">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={formData.receiveUpdates}
                                    onChange={(e) => setFormData({ ...formData, receiveUpdates: e.target.checked })}
                                />
                                Stay ahead of the curve with insights and trends delivered straight to your inbox. Subscribe for only product updates!
                            </label>
                        </div>

                        <div className="form-section form-actions-container">
                            <div className="captcha-section">
                                <label className="captcha-label">
                                    <input
                                        type="checkbox"
                                        onChange={handleCaptchaVerification}
                                        disabled={isCaptchaVerified}
                                    />
                                    Prove your human-ness (robots need not respond). <span className="mandatory-asterisk">*</span>
                                </label>
                            </div>
                            <div className="submit-section">
                                <button
                                    type="submit"
                                    className="submit-button"
                                    disabled={!isCaptchaVerified}
                                >
                                    Submit Responses
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </main>

            <footer className="form-footer">
                <p>&copy; {new Date().getFullYear()} Interview Trends. All rights reserved.</p>
            </footer>

            {isSuccessModalOpen && (
                <SuccessModal
                    message="You’re a rockstar! Your story’s in the vault. Stay tuned for the magic it creates!"
                    onClose={() => setIsSuccessModalOpen(false)}
                />
            )}
        </div>
    );
};

export default FormPage;
