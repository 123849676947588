import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaLinkedin, FaEnvelope, FaTimes, FaWhatsapp } from "react-icons/fa";
import TotalSubmissionsCounter from "./TotalSubmissionsCounter"; // Import counter component
import "../styles/SuccessModal.css";

const SuccessModal = ({ message, onClose, allowAnotherSubmission }) => {
  const navigate = useNavigate();
  const [totalSubmissions, setTotalSubmissions] = useState(0);

  useEffect(() => {
    const fetchTotalSubmissions = async () => {
      try {
        const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:4000";
        const response = await fetch(`${API_BASE_URL}/api/total-submissions`);

        if (response.ok) {
          const data = await response.json();
          setTotalSubmissions(data.totalSubmissions);
        } else {
          console.error("Failed to fetch total submissions.");
        }
      } catch (error) {
        console.error("Error fetching total submissions:", error);
      }
    };

    fetchTotalSubmissions();
  }, []);

  const handleClose = () => {
    navigate("/thank-you"); // Navigate to Thank You page
  };

  const handleAnotherSubmission = () => {
    navigate("/"); // Navigate back to the form
  };

  const handleLinkedInShare = () => {
    window.open(
      "https://www.linkedin.com/sharing/share-offsite/?url=https://mockvani-webapp.azurewebsites.net",
      "_blank"
    );
  };

  const handleEmailShare = () => {
    window.location.href = `mailto:?subject=Share your interview experiences&body=I just completed this great survey on interview questions. Join in: https://mockvani-webapp.azurewebsites.net`;
  };

  const handleWhatsAppShare = () => {
    const message = "Check out this great survey on interview experiences! Participate here: https://mockvani-webapp.azurewebsites.net";
    const whatsappURL = `https://wa.me/?text=${encodeURIComponent(message)}`;
    window.open(whatsappURL, "_blank");
  };

  return (
    <div className="modal-overlay">
      <div className="modal-box">
        <h2>🎉 Your story is now part of something big!</h2>
        <p>
          {message ||
            "Thank you for your submission! We’ve locked your story in the vault and will notify you with updates (if opted-in). Meanwhile, spread the word!"}
        </p>
        <p>
          Your insights could help shape the way we prepare for interviews.
          Together, we're building a legacy. Keep an eye on your inbox for updates from us!
        </p>

        {/* Total Submissions Counter */}
        <div className="total-submissions">
          <TotalSubmissionsCounter total={totalSubmissions} />
        </div>

        <div className="modal-actions">
          <button onClick={handleLinkedInShare} title="Share on LinkedIn">
            <FaLinkedin />
          </button>
          <button onClick={handleEmailShare} title="Share via Email">
            <FaEnvelope />
          </button>
          <button onClick={handleWhatsAppShare} title="Share on WhatsApp">
            <FaWhatsapp />
          </button>
          {allowAnotherSubmission && (
            <button onClick={handleAnotherSubmission} title="Add Another" className="secondary-button">
              Add Another
            </button>
          )}
          <button onClick={handleClose} title="Close" className="close-button">
            <FaTimes />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;
