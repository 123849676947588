import React, { useState, useEffect } from "react";
import { FaLinkedin, FaEnvelope, FaWhatsapp } from "react-icons/fa";
import TotalSubmissionsCounter from "./TotalSubmissionsCounter"; // Import counter component
import "../styles/ThankYouPage.css";

const ThankYouPage = () => {
  const [totalSubmissions, setTotalSubmissions] = useState(0);

  const handleLinkedInShare = () => {
    window.open(
      "https://www.linkedin.com/sharing/share-offsite/?url=https://mockvani-webapp.azurewebsites.net",
      "_blank"
    );
  };

  useEffect(() => {
    const fetchTotalSubmissions = async () => {
      try {
        const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:4000";
        const response = await fetch(`${API_BASE_URL}/api/total-submissions`);

        if (response.ok) {
          const data = await response.json();
          setTotalSubmissions(data.totalSubmissions);
        } else {
          console.error("Failed to fetch total submissions.");
        }
      } catch (error) {
        console.error("Error fetching total submissions:", error);
      }
    };

    fetchTotalSubmissions();
  }, []);

  const handleEmailShare = () => {
    window.location.href = `mailto:?subject=Share your interview experiences&body=I just completed this great survey on interview questions. Join in: https://mockvani-webapp.azurewebsites.net`;
  };

  const handleWhatsAppShare = () => {
    const message = "Check out this great survey on interview experiences! Participate here: https://mockvani-webapp.azurewebsites.net";
    const whatsappURL = `https://wa.me/?text=${encodeURIComponent(message)}`;
    window.open(whatsappURL, "_blank");
  };

  return (
    <div className="thank-you-page">
      <div className="thank-you-container">
        <h1>🎉 Thank You for Your Contribution!</h1>
        <p className="thank-you-message">
          Your insights are invaluable. We’ve captured your information and locked your story into our vault of wisdom.
        </p>
        <blockquote className="thank-you-quote">
          "Technology is best when it brings people together." – Matt Mullenweg
        </blockquote>
        <p className="thank-you-follow-up">
          If you opted for updates, you’ll hear from us soon with exciting insights. In the meantime, let’s amplify this mission by sharing the survey:
        </p>
        {/* Total Submissions Counter */}
        <div className="total-submissions">
          <TotalSubmissionsCounter total={totalSubmissions} />
        </div>
        <div className="share-actions">
          <button onClick={handleLinkedInShare} title="Share on LinkedIn" className="share-button linkedin">
            <FaLinkedin /> Share on LinkedIn
          </button>
          <button onClick={handleEmailShare} title="Share via Email" className="share-button email">
            <FaEnvelope /> Share via Email
          </button>
          <button onClick={handleWhatsAppShare} title="Share on WhatsApp" className="share-button whatsapp">
            <FaWhatsapp /> Share on WhatsApp
          </button>
        </div>
      </div>
      <div className="thank-you-footer">
        <p>&copy; {new Date().getFullYear()} Interview Trends. Together, we shape the future.</p>
      </div>
    </div>
  );
};

export default ThankYouPage;
