import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import FormPage from "./FormPage";
import ThankYouPage from "./ThankYouPage";
import TotalSubmissionsCounter from "./TotalSubmissionsCounter"; // Import the counter

const App = () => {
  return (
    <Router>
      <div>
        {/* Render the total submissions counter on all pages */}
        <TotalSubmissionsCounter />
        <Routes>
          <Route path="/" element={<FormPage />} /> {/* Main form page */}
          <Route path="/thank-you" element={<ThankYouPage />} /> {/* Thank-You page */}
        </Routes>
      </div>
    </Router>
  );
};

export default App;
